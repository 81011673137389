import React from 'react'
import {CategoryGridStyles} from '../../styles/category/CategoryGridStyles' 
import CategoryItem from './CategoryItem'
function CategoryGrid({categories}) {
    return (
        <CategoryGridStyles>
            {
                categories.map((item)=>{
                    return (
                    <CategoryItem slug={item.slug} key={item.id} title={item.title} description={item._rawDescription}/>
                    )
                })
            }
        </CategoryGridStyles>
    )
}

export default CategoryGrid
