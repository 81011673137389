import { graphql } from 'gatsby'
import React from 'react'
import CategoryGrid from '../components/category/CategoryGrid';
import PageHeader from '../components/PageHeader';
import PageSpace from '../components/PageSpace';
import Pagination from '../components/Pagination';
import SEO from '../components/SEOFile';
import AdSense from 'react-adsense';

export const CategoryListQuery = graphql`
    query categoriesQuery($limit: Int!, $offset: Int!){
        allSanityCategory(
            sort: {fields: _createdAt, order: DESC}
            limit: $limit
            skip: $offset
        ){
            nodes{
                id
                title
                slug{
                    current
                }
                _rawDescription
            }
        }
    }
`;

function CategoryList({data, pageContext}) {
    const {currentPage, numberOfPages} = pageContext;
    const categories = data.allSanityCategory.nodes;
    // console.log(numberOfPages)
    return (
        <>
            <SEO title="auctux-categories"/>
            <PageSpace top={80} bottom={100}>
                <div className='container'>
                    <PageHeader title="All categories" description="This month will bring about the 88th Academy award ceremony ..."/>
                    <CategoryGrid categories={categories}/>
                    {
                        numberOfPages > 1 && (
                            <Pagination
                                currentPage={currentPage}
                                numberOfPages={numberOfPages}
                                baseURL="/categories"
                            />
                        )
                    }
                </div>
            </PageSpace>
            <AdSense.Google
            client='ca-pub-7363395996736530'
            slot='7806394673'
            style={{ display: 'block' }}
            format='auto'
            responsive='true'
            layoutKey='-gw-1+2a-9x+5c'
            />
        </>
    )
}

export default CategoryList
