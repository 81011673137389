import React from 'react'
import { buttonTypes } from '../../constants/buttonTypes'
import {CategoryItemStyles} from '../../styles/category/CategoryItemStyles'
import Button from '../buttons/Button'
import MyPortableText from '../typography/PortableText'
import { RegularTitle } from '../typography/Title'
function CategoryItem({title, slug, description}) {
    return (
        <CategoryItemStyles>
            <RegularTitle className="title">{title}</RegularTitle>
            <div className="text">
                <MyPortableText value={description}/>
            </div>
            <Button to={`/categories/${slug.current}`} variant={buttonTypes.outline}>Explore Category</Button>
        </CategoryItemStyles>
    )
}

export default CategoryItem
