import React from 'react'
import {PageHeaderStyles} from '../styles/PageHeaderStyles';
import ParagraphText from './typography/ParagraphText';
import {SectionTitle} from './typography/Title'

function PageHeader({title, description, className, children}) {
    return (
        <div className={className} style={{display: "grid",flexWrap: "wrap", justifyContent: "center"}}>
            <PageHeaderStyles >
            <div className='container' style={{textAlign: 'center'}}>
            <SectionTitle style={{textAlign: 'center'}}>
                {title}
            </SectionTitle>
            <ParagraphText>
                {description}
            </ParagraphText>
            </div>
            
            {children}
            </PageHeaderStyles>
        </div>
    )
}

export default PageHeader
